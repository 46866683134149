import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VExpansionPanels,{attrs:{"light":"","depressed":"","accordion":""}},[_c(VExpansionPanel,{staticClass:"mt-4"},[_c(VExpansionPanelHeader,{attrs:{"color":"var(--primary)"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('font-awesome-icon',{staticStyle:{"font-size":"28px"},attrs:{"color":"var(--font-primary)","icon":"fa-solid fa-angles-down"}})]},proxy:true}])},[_c('p',{staticClass:"font-text-departamento"},[_vm._v(" "+_vm._s(_vm.departamento.titulo)+" ")])]),_c(VExpansionPanelContent,{staticClass:"pt-4"},[_c('div',{staticClass:"expansion-content"},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VImg,{staticStyle:{"background":"var(--details)"},attrs:{"lazy-src":require("@/assets/loading.gif"),"src":_vm.departamento.imagem_link}})],1),_c(VCol,[_c('pre',{staticClass:"font-text-expansion-departamento"},[_vm._v("              "+_vm._s(_vm.departamento.descricao)+"\n            ")])])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }