import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":_vm.$screenSize >= 960 ? '80%' : '99%',"max-width":"1100px"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c(VCard,{staticClass:"news-info-card",staticStyle:{"background":"var(--primary)"}},[_c(VCardTitle,{staticStyle:{"justify-content":"center"}},[_c('p',{staticClass:"new-title-font"},[_vm._v(_vm._s(_vm.news.titulo))])]),_c(VCardText,{style:(_vm.$screenSize >= 960 ? 'margin-top: 20px' : 'margin-top: 4px')},[_c(VRow,{staticClass:"news-row"},[_c(VCol,{attrs:{"cols":"12","md":"5"}},[_c(VImg,{staticStyle:{"margin-left":"5px","margin-right":"5px","background":"var(--details)"},attrs:{"lazy-src":require("@/assets/loading.gif"),"height":_vm.$screenSize >= 960 ? '450' : '200',"src":_vm.news.imagem_link}})],1),_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c('pre',{staticClass:"new-description-font"},[_vm._v(_vm._s(_vm.news.descricao))])])],1)],1),_c(VCardActions,{style:(_vm.$screenSize >= 960 ? 'margin-top: 10px' : 'margin-top: 25px')},[_c(VBtn,{attrs:{"text":"","large":"","radius":"","depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticStyle:{"color":"var(--font-primary)","font-size":"17px"}},[_vm._v("Voltar")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }