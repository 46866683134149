<template>
  <div>
    <v-expansion-panels light depressed accordion>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-header color="var(--primary)"
          ><p class="font-text-departamento">
            {{ departamento.titulo }}
          </p>
          <template v-slot:actions>
            <font-awesome-icon
              color="var(--font-primary)"
              style="font-size: 28px"
              icon="fa-solid fa-angles-down"
            />
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <div class="expansion-content">
            <v-col cols="12" md="6">
              <v-img lazy-src="@/assets/loading.gif"
        style="background:var(--details)" :src="departamento.imagem_link" />
            </v-col>
            <v-col>
              <pre class="font-text-expansion-departamento">
                {{ departamento.descricao }}
              </pre>
            </v-col>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: {
    departamento: {
      required: true,
    },
  },
};
</script>
<style>
.font-text-departamento {
  font-family: "secundary";
  color: var(--font-primary);
  font-weight: 600;
  letter-spacing: 0.04em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-style: normal;
  font-size: 25px;
  line-height: 27px;
}
.font-text-expansion-departamento {
  word-break: break-word;
  text-align: left;;
  font-family: "secundary";
  color: var(--primary);
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-style: normal;
  font-size: 19px;
  line-height: 22px;
}
.expansion-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}
</style>