<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 45px;
    "
  >
    <Titles :type="2" :text="'Avisos'" />
    <v-col cols="12" md="9" v-for="aviso in avisos" :key="aviso.id">
      <Aviso :aviso="aviso.attributes" />
    </v-col>
  </div>
</template>
<script>
import Aviso from "../components/Aviso.vue";
import Titles from "../../../components/Titles.vue";
export default {
  components: {
    Aviso,
    Titles,
  },
  props:{
    avisos: {
      required: true
    }
  }
};
</script>