<template>
  <div>
    <v-app-bar
      style="border-bottom: solid 2px var(--secundary)"
      elevation="0"
      fixed
      app
      color="var(--primary)"
      dark
      height="55px"
      depressed
      hide-on-scroll
      ref="appBar"
    >
      <v-row class="app-bar-boxe">
        <div class="d-flex align-center">
          <p class="app-bar-title-text">Clube Santa Rita</p>
        </div>

        <v-spacer></v-spacer>
        <v-app-bar-nav-icon v-if="$screenSize < 960" @click="drawer = true">
          <font-awesome-icon style="font-size: 25px" icon="fa-solid fa-bars" />
        </v-app-bar-nav-icon>
        <div v-if="$screenSize >= 960">
          <v-btn
            class="mr-1"
            @click="activeButton(0)"
            href="#"
            x-small
            depressed
            text
          >
            <p
              :class="{ 'app-bar-button-selected-text': selectedButton == 0 }"
              class="app-bar-buttons-text"
            >
              Início
            </p>
          </v-btn>
          <v-btn
            class="mr-1"
            @click="activeButton(1)"
            href="#"
            x-small
            depressed
            text
          >
            <p
              :class="{ 'app-bar-button-selected-text': selectedButton == 1 }"
              class="app-bar-buttons-text"
            >
              Clube
            </p>
          </v-btn>
          <v-btn
            class="mr-1"
            @click="activeButton(2)"
            href="#"
            x-small
            depressed
            text
          >
            <p
              :class="{ 'app-bar-button-selected-text': selectedButton == 2 }"
              class="app-bar-buttons-text"
            >
              Infraestrutura
            </p>
          </v-btn>
          <v-btn
            class="mr-1"
            @click="activeButton(3)"
            href="#"
            x-small
            depressed
            text
          >
            <p
              :class="{ 'app-bar-button-selected-text': selectedButton == 3 }"
              class="app-bar-buttons-text"
            >
              Imagens
            </p>
          </v-btn>
          <v-btn
            class="mr-1"
            @click="activeButton(4)"
            href="#"
            x-small
            depressed
            text
          >
            <p
              :class="{ 'app-bar-button-selected-text': selectedButton == 4 }"
              class="app-bar-buttons-text"
            >
              Departamentos
            </p>
          </v-btn>
          <v-btn
            class="mr-5"
            @click="activeButton(5)"
            href="#"
            x-small
            depressed
            text
          >
            <p
              :class="{ 'app-bar-button-selected-text': selectedButton == 5 }"
              class="app-bar-buttons-text"
            >
              Avisos
            </p>
          </v-btn>
          <v-btn
            class="app-bar-special-button"
            href="#"
            @click="activeButton(6)"
            style="height: 24px"
            rounded
            outlined
            small
            depressed
          >
            <p class="app-bar-special-buttons-text">Associe-se</p>
          </v-btn>
        </div>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      right
      @click:outside="drawer = false"
      v-model="drawer"
      color="var(--details)"
      style="max-height: 100vh"
      fixed
      temporary
    >
      <v-list absolute nav dense>
        <v-list-item-group>
          <v-list-item
            active-class="green--text text--accent-4"
            @click="activeButton(0)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :class="{
                  'app-bar-navigation-selected-icon': selectedButton == 0,
                }"
                class="app-bar-navigation-icon"
                style="font-size: 20px"
                icon="fa-solid fa-home"
              />
            </v-list-item-icon>
            <v-list-item-title
              :class="{
                'app-bar-navigation-selected-text': selectedButton == 0,
              }"
              class="app-bar-navigation-text"
              >Início</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            active-class="green--text text--accent-4"
            @click="activeButton(1)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :class="{
                  'app-bar-navigation-selected-icon': selectedButton == 1,
                }"
                class="app-bar-navigation-icon"
                style="font-size: 20px"
                icon="fa-solid fa-people-roof"
              />
            </v-list-item-icon>
            <v-list-item-title
              :class="{
                'app-bar-navigation-selected-text': selectedButton == 1,
              }"
              class="app-bar-navigation-text"
              >Clube</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            active-class="green--text text--accent-4"
            @click="activeButton(2)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :class="{
                  'app-bar-navigation-selected-icon': selectedButton == 2,
                }"
                class="app-bar-navigation-icon"
                style="font-size: 20px"
                icon="fa-solid fa-rectangle-list"
              />
            </v-list-item-icon>
            <v-list-item-title
              :class="{
                'app-bar-navigation-selected-text': selectedButton == 2,
              }"
              class="app-bar-navigation-text"
              >Infraestrutura</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            active-class="green--text text--accent-4"
            @click="activeButton(3)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :class="{
                  'app-bar-navigation-selected-icon': selectedButton == 3,
                }"
                class="app-bar-navigation-icon"
                style="font-size: 20px"
                icon="fa-solid fa-photo-film"
              />
            </v-list-item-icon>
            <v-list-item-title
              :class="{
                'app-bar-navigation-selected-text': selectedButton == 3,
              }"
              class="app-bar-navigation-text"
              >Imagens</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            active-class="green--text text--accent-4"
            @click="activeButton(4)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :class="{
                  'app-bar-navigation-selected-icon': selectedButton == 4,
                }"
                class="app-bar-navigation-icon"
                style="font-size: 20px"
                icon="fa-solid fa-box-archive"
              />
            </v-list-item-icon>
            <v-list-item-title
              :class="{
                'app-bar-navigation-selected-text': selectedButton == 4,
              }"
              class="app-bar-navigation-text"
              >Departamentos</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            active-class="green--text text--accent-4"
            @click="activeButton(5)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :class="{
                  'app-bar-navigation-selected-icon': selectedButton == 5,
                }"
                class="app-bar-navigation-icon"
                style="font-size: 20px"
                icon="fa-solid fa-bell"
              />
            </v-list-item-icon>
            <v-list-item-title
              :class="{
                'app-bar-navigation-selected-text': selectedButton == 5,
              }"
              class="app-bar-navigation-text"
              >Avisos</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            active-class="green--text text--accent-4"
            @click="activeButton(6)"
          >
            <v-list-item-icon>
              <font-awesome-icon
                :class="{
                  'app-bar-navigation-selected-icon': selectedButton == 6,
                }"
                class="app-bar-navigation-icon"
                style="font-size: 20px"
                icon="fa-solid fa-handshake"
              />
            </v-list-item-icon>
            <v-list-item-title
              :class="{
                'app-bar-navigation-selected-text': selectedButton == 6,
              }"
              class="app-bar-navigation-text"
              >Associe-se</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { Scroll } from 'vuetify/lib/directives/scroll'
export default {
   directives: {
    Scroll,
  },

  data() {
    return {
      selectedButton: 0,
      drawer: false,
    };
  },
  methods: {
    activeButton(selectedId) {
      this.selectedButton = selectedId;
      this.$emit("changeId", selectedId);
    },
     onScroll() {
      // @hack: https://github.com/vuetifyjs/vuetify/issues/9993
      const { appBar } = this.$refs
      if (appBar.currentScroll < appBar.currentThreshold) {
        appBar.isActive = true
      }
    },
  },
};
</script>

<style>
/* App-bar */
.app-bar-title-text {
  color: #ffffff;
  font-family: "appBar";
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 0.02em;
}
.app-bar-buttons-text,
.app-bar-special-buttons-text {
  font-family: "appBar";
  font-size: 15px;
  cursor: pointer;
  letter-spacing: 0.02em;
  line-height: 15px;
}
.app-bar-buttons-text {
  color: #ffffff;
}
.app-bar-button-selected-text {
  color: #ffb800;
}
.app-bar-special-buttons-text {
  font-weight: 600;
  color: #453200;
}

.app-bar-special-button {
  border: 1px solid #453200;
  background: #ffb800;
}
.app-bar-boxe {
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  width: 8vw;
  max-width: 1600px;
}

.app-bar-navigation-text,
.app-bar-navigation-selected-text {
  font-family: "primary";
  font-size: 17px;
  color: var(--black);
  cursor: pointer;
  letter-spacing: 0.02em;
  line-height: 19px;
}

.app-bar-navigation-selected-text {
  color: var(--secundary);
}
.app-bar-navigation-selected-icon {
  color: var(--secundary) !important;
}

.app-bar-navigation-icon {
  color: var(--black);
}

@media screen and (max-width: 960px) {
  .app-bar-boxe {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 1366px) and (min-width:960px) {
  .app-bar-boxe {
    max-width: 1200px;
  }
}
</style>