<template>
  <div class="banner-background">
    <v-carousel
      :height="$screenSize >= 960 ? 'calc(100vh - (95px + 5vh))' : '45vh'"
      hide-delimiter-background
      :hide-delimiters="$screenSize >= 960 ? false : true"
      :cycle="true"
      show-arrows-on-hover
      :show-arrows="true"
    >
      <v-carousel-item class="carrosel" v-for="img in images" :key="img.id">
        <v-img
          @click="openLink(img)"
          :min-height="
            $screenSize >= 960 ? 'calc(100vh - (95px + 5vh))' : '45vh'
          "
          min-width="100vw"
          :max-height="
            $screenSize >= 960 ? 'calc(100vh - (95px + 5vh))' : '45vh'
          "
          cointain
          class="carrosel-image"
          :src="img.attributes.imagem_link"
          lazy-src="@/assets/loading.gif"
          style="background: var(--tertiary)"
          :style="img.attributes.post_link ? 'cursor: pointer': ''"
        />
      </v-carousel-item>
    </v-carousel>
    <div v-if="$screenSize < 960" class="line-division"></div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      required: true,
    },
  },
  methods:{
    openLink(img) {
      if(img.attributes.post_link){
        window.open(img.attributes.post_link, "_blank").focus();
      } 
    },
  }
};
</script>
<style>
.banner-background {
  background: var(--primary);
  width: 100vw;
  height: calc(100vh - (95px + 5vh));
  /* min-height: 90vh;
  max-height: 95vh; */
  position: relative;
}
.carrosel {
  width: 100%;
  justify-content: center;
}
.carrosel-image {
  background: cover;
  width: 100%;
}

.line-division {
  margin-top: 1px;
  background: var(--details);
  height: 2px;
  width: 100vw;
  top: 0;
  border-radius: 15px;
}

@media screen and (max-width: 960px) {
  .banner-background {
    background: var(--primary);
    width: 100vw;
    height: 45vh;
    /* min-height: 90vh;
  max-height: 95vh; */
    position: relative;
  }
}

</style>
