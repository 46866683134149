<template>
  <v-col cols="10" md="11">
    <Titles :type="1" :text="'Rainhas'" />
    <VueSlickCarousel
      id="queens"
      v-if="queensArray.length"
      class="queens-carrousel"
      v-bind="settings"
    >
      <Card
        :years="true"
        :disableOnClick="true"
        class="queen-item"
        v-for="queen in queensArray"
        :key="queen.id"
        :card="queen"
      />
    </VueSlickCarousel>
    <!-- <v-carousel
      height="500"
      hide-delimiters
      show-arrows-on-hover
      :show-arrows="true"
    >
      <v-carousel-item class="queen-item"> </v-carousel-item>
    </v-carousel> -->
  </v-col>
</template>
<script>
import Titles from "../../../components/Titles.vue";
import Card from "../../../components/Card.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    Titles,
    Card,
    VueSlickCarousel,
  },
  created() {
    let count = 0;
    this.queensArray.map((q) => {
      this.queensArray[count].attributes.titulo = q.attributes.nome;
      count++;
    });
  },
  props: {
    queensArray: {
      required: true,
    },
  },
  data() {
    return {
      settings: {
        lazyLoad: "ondemand",
        arrows: true,
        dots: false,
        infinite: true,
        initialSlide: 1,
        speed: 200,
        slidesToShow: this.$screenSize >= 1366 ? 4 : 3,
        slidesToScroll: 5,
        touchThreshold: 5,
        centerMode: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              fade: true,
              lazyLoad: "ondemand",
              arrows: false,
              dots: true,
              infinite: true,
              initialSlide: 1,
              speed: 200,
              slidesToShow: 1,
              focusOnSelect: true,
              slidesToScroll: 5,
              touchThreshold: 5,
              centerMode: false,
            },
          },
        ],
        // {
        //   "breakpoint": 600,
        //   "settings": {
        //     "slidesToShow": 2,
        //     "slidesToScroll": 2,
        //     "initialSlide": 2
        //   }
        // },
        // {
        //   "breakpoint": 480,
        //   "settings": {
        //     "slidesToShow": 1,
        //     "slidesToScroll": 1
        //   }
        // }
        // ]
      },
    };
  },
};
</script>
<style>
.queen-item {
  height: 500px;
}
.queens-carrousel {
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.slick-slide {
  margin-left: 2vw;
}
@media screen and (max-width: 960px) {
  .slick-slide {
    margin-left: 0vw;
  }
}
</style>