<template>
  <v-dialog
    @click:outside="$emit('close')"
    v-model="isVisible"
    :width="$screenSize >= 960 ? '80%' : '99%'"
    max-width="1100px"
  >
    <v-card style="background: var(--primary)" class="news-info-card">
      <v-card-title style="justify-content: center">
        <p class="new-title-font">{{ news.titulo }}</p>
      </v-card-title>
      <v-card-text
        :style="$screenSize >= 960 ? 'margin-top: 20px' : 'margin-top: 4px'"
      >
        <v-row class="news-row">
          <v-col cols="12" md="5">
            <v-img
              lazy-src="@/assets/loading.gif"
              :height="$screenSize >= 960 ? '450' : '200'"
              style="
                margin-left: 5px;
                margin-right: 5px;
                background: var(--details);
              "
              :src="news.imagem_link"
            />
          </v-col>
          <v-col cols="12" md="7">
            <pre class="new-description-font">{{ news.descricao }}</pre>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        :style="$screenSize >= 960 ? 'margin-top: 10px' : 'margin-top: 25px'"
      >
        <v-btn text large @click="$emit('close')" radius depressed
          ><span style="color: var(--font-primary); font-size: 17px"
            >Voltar</span
          ></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    news: {
      type: Object,
    },
    isVisible: {
      default: false,
    },
  },
};
</script>
<style>
.news-row {
  display: flex;
  flex-wrap: nowrap;
}
.news-info-card {
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 15px;
}
.new-description-font {
  height: 450px;
  overflow-y: auto;
  margin-left: 20px;
  font-family: "tertiary";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--font-primary);
  text-align: left;
}
.new-title-font {
  padding-bottom: 8px;
  font-family: "primary";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: var(--font-primary);
  text-align: center;
  word-break: keep-all;
  word-break: keep-all;
}
@media screen and (max-width: 960px) {
  .news-info-card {
    padding-bottom: 15px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .new-title-font {
    word-break: break-word;
    padding-bottom: 8px;
    font-family: "secundary";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0em;
    color: var(--font-primary);
    text-align: center;
    word-break: keep-all;
    word-break: keep-all;
  }
  .news-row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .new-description-font {
    height: auto;
    overflow-y: hidden;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--font-primary);
    text-align: left;
  }
}
</style>