<template>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <Contacts />
    <Maps />
  </div>
</template>
<script>
import Contacts from "../components/Contacts.vue";
import Maps from "../components/Maps.vue";
export default {
  components: {
    Contacts,
    Maps,
  },
};
</script>