import axios from 'axios'
export default class RestResource {

  async sendRequest(endPoint) {
    // console.log('https://api-santa-rita.herokuapp.com/api/' + endPoint)
    // let res = await axios.get('https://api-santa-rita.herokuapp.com/api/' + endPoint)
    let res = await axios.get('https://santaapi.1.us-1.fl0.io/api/' + endPoint)

    
    // console.log('http://192.168.1.10:1337/api/' + endPoint)
    // let res = await axios.get('http://localhost:1337/api/' + endPoint)
    return await res.data.data
  }

}



