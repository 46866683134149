import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"maps-content"},[_c('Titles',{attrs:{"type":1,"text":'Onde nos encontrar'}}),_c(VCol,{staticClass:"mwidth",staticStyle:{"justify-content":"center","align-items":"center","display":"flex"}},[_c(VRow,{staticClass:"flex",staticStyle:{"justify-content":"space-around"}},[_c(VCol,{attrs:{"cols":"11","md":"6","lg":"5"}},[_c('p',{staticClass:"map-title-font"},[_vm._v("Sede social")]),_c('a',{staticClass:"map-title-text",attrs:{"href":"https://www.google.com/maps/place/Clube+Santa+Rita/@-29.2162983,-51.3464311,15z/data=!4m5!3m4!1s0x0:0xfae6a2d5f1729ceb!8m2!3d-29.2162983!4d-51.3464311","target":"_blank"}},[_c(VImg,{staticStyle:{"border-radius":"3px","background":"var(--tertiary)"},attrs:{"lazy-src":require("@/assets/loading.gif"),"height":_vm.$screenSize > 960 && _vm.$screenSize <= 1366
                ? '480'
                : _vm.$screenSize >= 960
                ? '600'
                : '260',"src":require("@/assets/mapaClube.jpeg")}}),_c('p',{staticClass:"mt-2"},[_vm._v(" Rua Vêneto, 233, Nova Vicenza, Farroupilha - RS ")])],1)]),(_vm.$screenSize >= 960)?_c('div',{staticStyle:{"width":"8px","height":"550px","border-radius":"15px","background":"var(--font-primary)","margin-top":"122px","margin-bottom":"10px"}}):_vm._e(),_c(VCol,{attrs:{"cols":"11","md":"6","lg":"5"}},[_c('p',{staticClass:"map-title-font"},[_vm._v("Sede campestre")]),_c('a',{staticClass:"map-title-text",attrs:{"href":"https://www.google.com/maps/place/Sede+Campestre+Clube+Santa+Rita/@-29.2262054,-51.3819404,15z/data=!4m2!3m1!1s0x0:0xc1d9438a826cfb1?sa=X&ved=2ahUKEwi0wNmYnKr7AhWIppUCHcGjAO8Q_BJ6BAhKEAg","target":"_blank"}},[_c(VImg,{staticStyle:{"border-radius":"3px","background":"var(--tertiary)"},attrs:{"lazy-src":require("@/assets/loading.gif"),"height":_vm.$screenSize > 960 && _vm.$screenSize <= 1366
                ? '480'
                : _vm.$screenSize >= 960
                ? '600'
                : '260',"src":require("@/assets/mapaSede.jpeg")}}),_c('p',{staticClass:"mt-2"},[_vm._v("Rua Coronel Pena de Moraes, Farroupilha - RS")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }