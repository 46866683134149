<template>
  <div
    style="
      background: var(--primary);
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- <Carrosel /> -->
    <About class="club-content" :text="clube.sobre.attributes.sobre" />
    <Mission class="club-content" :text="clube.sobre.attributes.missao" />
    <Vision class="club-content" :text="clube.sobre.attributes.visao" />
    <Directors
      class="club-content"
      :directorsArray="clube.casais"
      :president_img="clube.sobre.attributes.diretor_imagem"
      :president_text="clube.sobre.attributes.diretoria_texto"
      :president_name="clube.sobre.attributes.diretor_nome"
    />
    <Queens class="club-content" :queensArray="clube.rainhas" />
    <Presidents
      style="margin-bottom: 45px"
      class="club-content"
      :presidentsArray="clube.presidentes"
    /> 
  </div>
</template>
<script>
import Carrosel from "../../../components/Carrosel.vue";
import About from "../components/About.vue";
import Mission from "../components/Mission.vue";
import Vision from "../components/Vision.vue";
import Directors from "../components/Directors.vue";
import Queens from "../components/Queens.vue";
import Presidents from "../components/Presidents.vue";
export default {
  components: {
    Carrosel,
    About,
    Mission,
    Vision,
    Directors,
    Queens,
    Presidents,
  },
  props: {
    clube:{
      required: true
    }
  },
};
</script>
<style>
.club-font {
  padding-left: 8px;
  padding-right: 8px;
  font-family: "secundary";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: var(--font-primary);
  text-align: left;;
}
.club-content {
  max-width: 1600px;
}
@media screen and (max-width: 1366px) and (min-width:960px) {
  .club-content {
    max-width: 1200px;
  }
}

@media screen and (max-width: 960px) {
  .club-font {
    padding-left: 4px;
    padding-right: 4px;
    font-family: "secundary";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: var(--font-primary);
    text-align: left;;
  }
}
</style>