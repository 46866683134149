<template>
  <v-col cols="10" md="11">
    <Titles :type="1" :text="'Presidentes'" />
    <VueSlickCarousel
      v-if="presidentsArray.length"
      class="queens-carrousel"
      v-bind="settings"
    >
      <Card
      :years="true"
        :disableOnClick="true"
        class="queen-item"
        v-for="president in presidentsArray"
        :key="president.id"
        :card="president"
      />
    </VueSlickCarousel>
    <!-- <v-carousel
      height="500"
      hide-delimiters
      show-arrows-on-hover
      :show-arrows="true"
    >
      <v-carousel-item class="queen-item"> </v-carousel-item>
    </v-carousel> -->
  </v-col>
</template>
<script>
import Titles from "../../../components/Titles.vue";
import Card from "../../../components/Card.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    Titles,
    Card,
    VueSlickCarousel,
  },
  props: {
    presidentsArray: {},
  },
  created(){
    let count = 0
    this.presidentsArray.map((p) => {
      this.presidentsArray[count].attributes.titulo = p.attributes.nome 
      count++
    })
  },
  data() {
    return {
      settings: {
        lazyLoad: "ondemand",
        arrows: true,
        dots: false,
        infinite: true,
        initialSlide: 1,
        speed: 200,
        slidesToShow: this.$screenSize >= 1366 ? 4 : 3,
        slidesToScroll: 5,
        touchThreshold: 5,
        centerMode: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              fade:true,
              lazyLoad: "ondemand",
              arrows: false,
              dots: true,
              infinite: true,
              initialSlide: 1,
              speed: 200,
              slidesToShow: 1,
              focusOnSelect: true,
              slidesToScroll: 5,
              touchThreshold: 5,
              centerMode: false,
            },
          },
        ],
      },
    };
  },
};
</script>
<style>
.queen-item {
  height: 500px;
}
.queens-carrousel {
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.slick-list{
  height: 500px;
}
.slick-slide {
  margin-left: 2vw;
}
@media screen and (max-width: 960px) {
  .slick-slide {
    margin-left: 0vw;
  }
}
</style>