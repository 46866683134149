<template>
  <div class="news-content-background">
    <Titles :type="2" :text="'Novidades'" />
    <div class="news-box-content">
      <div
        @click="openNew(n)"
        class="news-box"
        v-for="n in mainNews"
        :key="n.id"
      >
        <v-img
          lazy-src="@/assets/loading.gif"
          :height="
            $screenSize > 960 && $screenSize <= 1366
              ? '480'
              : $screenSize >= 960
              ? '600'
              : '260'
          "
          style="border-radius: 3px; background: var(--tertiary)"
          :src="n.attributes.imagem_link"
        />
        <p class="font-title-news">{{ n.attributes.titulo }}</p>
      </div>
    </div>
    <div v-if="$screenSize >= 960" class="low-news-box-content">
      <div
        @click="openNew(nL)"
        class="low-news-box"
        v-for="nL in lowNews"
        :key="nL.id"
      >
        <v-img
          lazy-src="@/assets/loading.gif"
          :height="$screenSize >= 960 ? '290' : '190'"
          style="
            border-radius: 3px;
            background: var(--tertiary);
          "
          :src="nL.attributes.imagem_link"
        />
        <p class="low-font-title-news">{{ nL.attributes.titulo }}</p>
      </div>
    </div>
    <NewInfo
      :screenSize="$screenSize"
      @close="closeNew"
      v-if="showNewInfo"
      :isVisible="showNewInfo"
      :news="selectedNew.attributes"
    />
  </div>
</template>
<script>
import Titles from "../../../components/Titles.vue";
import NewInfo from "../modals/NewInfo.vue";
export default {
  components: {
    Titles,
    NewInfo,
  },
  props: {
    news: {
      required: true,
    },
  },
  created() {
    this.news.map((n) => {
      if (n.attributes.destaque) {
        this.mainNews.push(n);
      } else {
        this.lowNews.push(n);
      }
    });
  },
  methods: {
    openNew(selectedNew) {
      this.selectedNew = selectedNew;
      if (selectedNew.attributes.post_link) {
        window.open(selectedNew.attributes.post_link, "_blank").focus();
      } else {
        this.showNewInfo = true;
      }
    },
    closeNew() {
      this.selectedNew = null;
      this.showNewInfo = false;
    },
  },
  data() {
    return {
      mainNews: [],
      lowNews: [],
      selectedNew: null,
      showNewInfo: false,
    };
  },
};
</script>
<style>
.news-content-background {
  background: var(--details);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.news-box-content {
  max-width: 1600px;
  cursor: pointer;
  align-self: center;
  width: 85vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.news-box {
  width: 46%;
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.low-news-box {
  width: 22%;
  border-radius: 10px;
  margin-left: 1.4%;
  margin-right: 1.4%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.low-news-box-content {
  cursor: pointer;
  max-width: 1600;
  overflow-x: auto;
  margin-top: 20px;
  align-self: center;
  width: 95vw;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: center;
}

.low-font-title-news {
  color: #105c51;
  font-weight: 500;
  font-family: "tertiary";
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  display: flex;
   text-align: center;
  word-break: keep-all;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 6px;
}

.font-title-news {
  font-family: "secundary";
  color: #105c51;
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  display: flex;
   text-align: center;
  word-break: keep-all;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 8px;
}
.content {
  max-width: 1600px;
}

@media screen and (max-width: 960px) {
  .news-box-content {
    align-self: center;
    width: 93vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .low-news-box-content {
    overflow-x: none;
    align-self: center;
    width: 93vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .news-box {
    width: 100% ;
    border-radius: 10px;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .low-news-box {
    width: 100%;
    border-radius: 10px;
    margin-left: 1.4%;
    margin-right: 1.4%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .low-font-title-news {
    font-weight: 600;
    font-family: "secundary";
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
     text-align: center;
  word-break: keep-all;
    margin-top: 6px;
    padding-bottom: 14px;
  }

  .font-title-news {
    font-weight: 600;
    font-family: "secundary";
    font-size: 21px;
    line-height: 22px;
    margin-top: 16px;
    padding-bottom: 20px;
    margin-left: 3px;
    margin-right: 3px;
  }
}
@media screen and (max-width: 1366px) and (min-width:960px) {
  .news-box-content {
    max-width: 1200px;
  }
  .low-news-box-content {
    max-width: 1200px;
  }
  .news-box {
    width: 43%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4%;
  }
  .low-font-title-news {
    font-size: 18px;
    line-height: 20px;
    margin-top: 6px;
  }
  .font-title-news {
    font-size: 28px;
    line-height: 30px;
  }
}
</style>