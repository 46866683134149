<template>
  <div style="display:flex; flex-direction: column; align-items: center; margin-bottom:45px">
    <Carrosel :images="home.banners"/>
    <Cards :cards="home.cards" />
    <News :news="home.news" />
  </div>
</template>
<script>
import Carrosel from "../../../components/Carrosel.vue";
import Cards from "../components/Cards.vue";
import News from "../components/News.vue";
export default {
  props: {
    home:{
      required: true
    }
  },
  data() {
    return {
      images: [],
      cards: [],
      news: []
    };
  },
  components: {
    Carrosel,
    Cards,
    News
  },
};
</script>