import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content-background"},[_c('Titles',{attrs:{"type":2,"text":'Novidades'}}),_c('div',{staticClass:"news-box-content"},_vm._l((_vm.mainNews),function(n){return _c('div',{key:n.id,staticClass:"news-box",on:{"click":function($event){return _vm.openNew(n)}}},[_c(VImg,{staticStyle:{"border-radius":"3px","background":"var(--tertiary)"},attrs:{"lazy-src":require("@/assets/loading.gif"),"height":_vm.$screenSize > 960 && _vm.$screenSize <= 1366
            ? '480'
            : _vm.$screenSize >= 960
            ? '600'
            : '260',"src":n.attributes.imagem_link}}),_c('p',{staticClass:"font-title-news"},[_vm._v(_vm._s(n.attributes.titulo))])],1)}),0),(_vm.$screenSize >= 960)?_c('div',{staticClass:"low-news-box-content"},_vm._l((_vm.lowNews),function(nL){return _c('div',{key:nL.id,staticClass:"low-news-box",on:{"click":function($event){return _vm.openNew(nL)}}},[_c(VImg,{staticStyle:{"border-radius":"3px","background":"var(--tertiary)"},attrs:{"lazy-src":require("@/assets/loading.gif"),"height":_vm.$screenSize >= 960 ? '290' : '190',"src":nL.attributes.imagem_link}}),_c('p',{staticClass:"low-font-title-news"},[_vm._v(_vm._s(nL.attributes.titulo))])],1)}),0):_vm._e(),(_vm.showNewInfo)?_c('NewInfo',{attrs:{"screenSize":_vm.$screenSize,"isVisible":_vm.showNewInfo,"news":_vm.selectedNew.attributes},on:{"close":_vm.closeNew}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }