<template>
  <v-dialog
    open-delay
    close-delay
    @click:outside="$emit('close')"
    v-model="isVisible"
    :width="$screenSize >= 960 ? '80%' : '96%'"
    max-width="900px"
  >
    <v-card class="card-info">
      <v-card-title style="justify-content: center">
        <p class="title-font">{{ card.titulo }}</p>
      </v-card-title>
      <v-card-text>
        <v-img
          lazy-src="@/assets/loading.gif"
          height="350"
          width="'80%'"
          style="
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 10px;
            background: var(--tertiary);
          "
          :src="card.imagem_link"
        />
        <pre class="description-font">{{ card.descricao }}</pre>
      </v-card-text>
      <v-card-actions :class="$screenSize >= 960 ? '' : 'py-6'">
        <v-btn
          rounded
          @click="$emit('close')"
          color="var(--primary)"
          radius
          depressed
          ><span style="color: var(--font-primary); padding: 18px"
            >Voltar</span
          ></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    card: {
      type: Object,
    },
    isVisible: {
      default: false,
    },
  },
};
</script>
<style>
.description-font {
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
  font-family: "tertiary";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0em;
  color: var(--primary);
  text-align: left;
}
.title-font {
  padding-bottom: 8px;
  font-family: "primary";
  font-style: normal;
  font-weight: 900;
  font-size: 37px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: var(--primary);
  text-align: center;
  word-break: keep-all;
}
.card-info {
  padding: 15px;
}

@media screen and (max-width: 960px) {
  .title-font {
    padding-bottom: 8px;
    font-family: "primary";
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 36px;
    letter-spacing: 0em;
    color: var(--primary);
    text-align: center;
    word-break: keep-all;
  }
  .card-info {
    padding: 2px;
  }

  .description-font {
    padding-top: 20px;
    padding-bottom: 6px;
    font-family: "tertiary";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--primary);
    text-align: left;
  }
}
</style>