<template>
  <div class="card-box" @click="openCard(card.id)">
    <div class="image-card-box">
      <v-img
        height="350"
        class="image-margins"
        :src="card.attributes.imagem_link"
        lazy-src="@/assets/loading.gif"
        style="background:var(--tertiary)"
      />
    </div>
    <div class="text-card-box">
      <pre class="px-1 font-text-card">{{ card.attributes.titulo }}</pre>
      <pre v-if="years" class="px-1 font-years-card">{{ card.attributes.anos || card.attributes.ano}}</pre>
    </div>
    <CardInfos
      @close="closeInfo"
      v-if="selectedCardId != null"
      :isVisible="selectedCardId != null ? true : false"
      :card="card.attributes"
    />
  </div>
</template>
<script>
import CardInfos from "../modals/CardInfos.vue";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    years: {
      default:false
    },
    disableOnClick: {
      default: false,
    },
  },
  components: {
    CardInfos,
  },
  data() {
    return {
      selectedCardId: null,
    };
  },
  methods: {
    closeInfo() {
      this.selectedCardId = null;
    },
    openCard(id) {
      if (!this.disableOnClick) {
        this.selectedCardId = id;
      }
    },
  },
};
</script>
<style>
.image-margins {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.card-box {
  max-width: 450px;
  cursor: pointer;
  width: 28vw;
  min-width: 400px;
  height: 500px;
  background: var(--tertiary);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  margin-left: 2vw;
  margin-right: 2vw;
  display: flex;
  flex-direction: column;
}
.image-card-box {
  height: 350px;
}
.text-card-box {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.font-text-card {
  word-break: break-word;
  font-family: "primary";
  color: #105c51;
  font-weight: 600;
  letter-spacing: 0.02em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-style: normal;
  font-size: 38px;
  line-height: 40px;
  word-break: break-all;
}
.font-years-card{
  word-break: break-word;
  font-family: "primary";
  color: #105c51;
  font-weight: 600;
  letter-spacing: 0em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-style: normal;
  font-size: 28px;
  line-height: 38px;
  word-break: break-all;
}

@media screen and (max-width: 960px) {
  .card-box {
    width: 92vw;
    min-width: 250px;
    height: 500px;
    background: var(--tertiary);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 1366px) and (min-width:960px) {
  .card-box {
    width: 20vw;
    min-width: 370px;
    margin-left: 1vw;
    margin-right: 1vw;
    border-radius: 5px;
  }
  .image-margins {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
}
</style>