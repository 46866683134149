<template>
  <v-app class="overflows" style="background: var(--details)">
    <v-col cols="12" class="justify-center align-center d-flex flex-column" v-if="load">
      <v-progress-circular
        :color="'var(--primary)'"
        size="350"
        rotate="270"
        width="20"
        :value="value"
      >
      <p class="load-text">{{value}}%</p>
      </v-progress-circular>
      <p class="load-text pt-3 text-center">Carregando nossa página, aguarde...</p>
    </v-col>
    <AppBar v-if="!load" @changeId="changeScreen" />
    <v-main v-if="!load">
      <Home :home="home" v-if="screenId == 0" />
      <Clube :clube="clube" v-if="screenId == 1" />
      <Infra :infra="infraestrutura" v-if="screenId == 2" />
      <Images :imagens="imagens" v-if="screenId == 3" />
      <Departamentos :departamentos="departamentos" v-if="screenId == 4" />
      <Avisos :avisos="avisos" v-if="screenId == 5" />
      <Associar v-if="screenId == 6" />
    </v-main>
    <Footer v-if="!load" />
  </v-app>
</template>
<script>
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import Home from "./modules/home/view/Container";
import Associar from "./modules/associar/view/Container";
import Avisos from "./modules/avisos/view/Container";
import Infra from "./modules/infra/view/Container";
import Clube from "./modules/clube/view/Container";
import Images from "./modules/fotos/view/Container";
import Departamentos from "./modules/departamentos/view/Container";
import RestResource from "./service/index";
export default {
  name: "App",
  async created() {
    if (this.$screenSize < 960) {
      document.documentElement.style.setProperty("--carrousel", "0px");
    }

    const restResourceService = new RestResource();

    this.load = true;
    this.value = 10;

    if (this.$screenSize < 960) {
      this.home.banners = await restResourceService.sendRequest("banners?filters[mobile][$eq]=true");
    }else{
      this.home.banners = await restResourceService.sendRequest("banners?filters[mobile][$eq]=false");
    }
    this.value = 14;
    this.home.cards = await restResourceService.sendRequest("cartoes");
    this.value = 25;
    this.home.news = await restResourceService.sendRequest("noticias?sort[0]=data:desc");
    // this.home.news = this.home.news.sort(function(a,b) {
    //   return new Date(a.attributes.data) > new Date(b.attributes.data) 
    // })
    this.value = 40;

    this.clube.sobre = await restResourceService.sendRequest("sobre-o-clube");
    this.value = 47;
    this.clube.casais = await restResourceService.sendRequest(
      "casais-presidentes"
    );
    this.value = 54;
    this.clube.presidentes = await restResourceService.sendRequest(
      "presidentes"
    );
    this.value = 58;
    this.clube.rainhas = await restResourceService.sendRequest("rainhas");

    this.value = 68;

    this.infraestrutura = await restResourceService.sendRequest(
      "infraestruturas"
    );

    this.value = 73;

  //  this.imagens = await restResourceService.sendRequest(
  //     "categorias-de-imagens"
  //   );
    this.value = 77;

    this.imagens = await restResourceService.sendRequest("categorias-de-imagens?populate=*");

    this.value = 85;

    this.departamentos = await restResourceService.sendRequest("departamentos");

    this.value = 96;

    this.avisos = await restResourceService.sendRequest("avisos");

    this.value = 99;
    this.load = false;
  },
  components: {
    Home,
    AppBar,
    Footer,
    Associar,
    Avisos,
    Clube,
    Infra,
    Images,
    Departamentos,
  },
  methods: {
    // async sendRequests(id) {
    //   const restResourceService = new RestResource();
    //   switch (id) {
    //     case 0:
    //       if (!this.home.loaded) {
    //         this.home.banners = await restResourceService.sendRequest(
    //           "banners"
    //         );
    //         this.home.cards = await restResourceService.sendRequest("cartoes");
    //         this.home.news = await restResourceService.sendRequest("noticias");
    //         this.home.loaded = true;
    //       }
    //       break;
    //     case 1:
    //       if (!this.clube.loaded) {
    //         this.clube.sobre = await restResourceService.sendRequest(
    //           "sobre-o-clube"
    //         );
    //         this.clube.casais = await restResourceService.sendRequest(
    //           "casais-presidentes"
    //         );
    //         this.clube.presidentes = await restResourceService.sendRequest(
    //           "presidentes"
    //         );
    //         this.clube.rainhas = await restResourceService.sendRequest(
    //           "rainhas"
    //         );
    //         this.clube.loaded = true;
    //       }
    //       break;
    //     case 2:
    //       if (!this.infraestrutura.loaded) {
    //         this.infraestrutura = await restResourceService.sendRequest(
    //           "infraestruturas"
    //         );
    //         this.infraestrutura.loaded = true;
    //       }
    //       break;
    //     case 3:
    //       if (!this.imagens.loaded) {
    //         let categorias = await restResourceService.sendRequest(
    //           "categorias-de-imagens"
    //         );
    //         let fotos = await restResourceService.sendRequest("imagens");
    //         let count = 0;
    //         categorias.map((c) => {
    //           categorias[count].attributes.imagens = [];
    //           fotos.map((f) => {
    //             if (f.attributes.codigo_da_categoria == c.id) {
    //               categorias[count].attributes.imagens.push(f);
    //             }
    //           });
    //           count++;
    //         });
    //         this.imagens = categorias;
    //         this.imagens.loaded = true;
    //       }
    //       break;
    //     case 4:
    //       this.departamentos = await restResourceService.sendRequest(
    //         "departamentos"
    //       );
    //       this.departamentos.loaded = true;
    //       break;
    //     case 5:
    //       this.avisos = await restResourceService.sendRequest("avisos");
    //       this.avisos.loaded = true;
    //       break;
    //     case 6:
    //       break;
    //   }
    // },
    async changeScreen(id) {
      // await this.sendRequests(id);
      this.screenId = id;
    },
  },
  data: () => ({
    load: false,
    value: 0,
    screenId: 0,
    home: {
      loaded: false,
    },
    imagens: {
      loaded: false,
    },
    departamentos: {
      loaded: false,
    },
    infraestrutura: {
      loaded: false,
    },
    avisos: {
      loaded: false,
    },
    clube: {
      loaded: false,
    },
    associar: {
      loaded: false,
    },
  }),
};
</script>
<style>
pre {
  white-space: break-spaces;
}

.load-text{
  color: var(--primary);
  display: block;
  font-style: normal;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: "secundary";
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;
}

.mwidth{
  max-width:1600px
}
@media screen and (max-width: 1366px) and (min-width:960px){
  .mwidth {
    max-width: 1200px;
  }
}

/* Vuetify adjusts */
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98% !important;
}
.v-application p {
  margin-bottom: 0px;
}
.v-toolbar__content {
  justify-content: center;
}
.v-btn {
  text-transform: none;
}

.content-scroll {
  height: calc(100vh - 73px);
  overflow-y: auto;
  overflow-x: hidden;
}
.content-scroll.fill-height {
  height: 100%;
}
::-webkit-scrollbar-track {
  background: #f9f9f9;
}
::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background-color: #c9c9c9;
}
*::-webkit-scrollbar {
  border-radius: 40px;
  width: 5px;
}
*::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 40px;
}
*::-webkit-scrollbar-thumb {
  background-color: #bbbaba;
  border-radius: 40px;
}
.content-scroll::-webkit-scrollbar {
  border-radius: 40px;
  width: 5px;
}
.content-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 40px;
}
.content-scroll::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 40px;
}
.custom-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
.light-scroll {
  overflow: auto;
}
.light-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 5px;
}
.light-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}
.light-scroll::-webkit-scrollbar-thumb {
  /* background-color: #f0f0f0; */
  background-color: #efefef !important;
  border-radius: 30px;
}
.custom-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  /* background-color: #f0f0f0; */
  background-color: #c9c9c9;
  border-radius: 30px;
}

@media screen and (max-width: 960px) {
  .v-dialog {
    margin: 10px;
  }
  .v-card__text {
    padding: 0px 12px !important;
  }
  .overflows {
    overflow-y: hidden;
  }
}

:root {
  --carrousel: 2vw;
}
</style>