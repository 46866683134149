<template>
  <div class="cards-content-background">
    <Titles :type="1" :text="'Associe-se'" />
    <div class="cards-box-content">
      <Card

        :card="card"
        style="margin-bottom: 4vw"
        v-for="card in cards"
        :key="card.id"
      />
    </div>
  </div>
</template>
<script>
import Titles from "../../../components/Titles.vue";
import Card from "../../../components/Card.vue";
export default {
  components: {
    Titles,
    Card,
  },
  props: {
    cards: {
      required: true,
      default: null
    },
  },
};
</script>
<style>
.cards-content-background {
  background: var(--primary);
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cards-box-content {
  max-width: 1600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 1366px) and (min-width:960px) {
  .cards-box-content {
    max-width: 1200px;
  }
}
</style>