<template>
  <v-col cols="12" md="11">
    <Titles :type="1" :text="'Diretoria'" />
    <v-row>
      <v-col cols="12" md="5">
        <v-img
          height="650"
          lazy-src="@/assets/loading.gif"
          style="margin-left: 5px; margin-right: 5px; border-radius: 5px; background:var(--details)"
          :src="president_img"
        />
        <p class="name-font">{{ president_name }}</p>
      </v-col>
      <v-col cols="12" md="7">
        <pre class="club-font">{{ president_text }}</pre>
      </v-col>
    </v-row>
    <v-col class="directors-box" cols="12">
      <v-row v-for="director in directorsArray" :key="director.id">
        <p class="names-font">
          <span class="font-bold">{{ director.attributes.cargo + ": " }}</span
          >{{ director.attributes.nomes }}
        </p>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
import Titles from "../../../components/Titles.vue";
export default {
  components: {
    Titles,
  },
  props: {
    directorsArray: {
      type: Array,
    },
    president_text: {
      type: String,
    },
    president_img: {
      type: String,
    },
    president_name: {
      type: String,
    },
  },
  created(){
    this.directorsArray.sort((a,b) => {
      if(a.attributes.posicao > b.attributes.posicao){
        return 1
      }
      if(a.attributes.posicao < b.attributes.posicao){
        return -1
      }
    })
  }
};
</script>
<style>
.directors-box {
  padding-top: 48px;
  padding-bottom: 18px;
}
.name-font {
  padding-top: 8px;
  font-family: "secundary";
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--font-primary);
  text-align: center;
}
.names-font {
  padding-top: 15px;
  font-family: "tertiary";
  font-style: normal;
  font-weight: 00;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: var(--font-primary);
  text-align: center;
}

@media screen and (max-width: 960px) {
  .names-font {
    padding-top: 35px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: "tertiary";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--font-primary);
    text-align: left;;
  }
}
</style>
