<template>
  <v-col cols="12" md="11">
    <Titles :type="1" :text="'Missão'" />
    <pre class="club-font">{{text}}</pre>
  </v-col>
</template>
<script>
import Titles from "../../../components/Titles.vue";
export default {
  components: {
    Titles,
  },
  props:{
    text: {
        type: String
    }
  }
};
</script>
