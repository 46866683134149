<template>
  <div style="margin-bottom: 45px; min-height: 800px; display:flex; flex-wrap: wrap; justify-content: center;">
    <Card :card="card"
        style="margin-bottom: 4vw"
        v-for="card in infraArray"
        :key="card.id"/>
  </div>
</template>
<script>
import Card from "@/components/Card.vue";
export default {
  components: {
    Card,
  },
  props: {
    infraArray: {
      type: Array,
    },
  },
};
</script>