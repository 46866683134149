<template>
  <div
    style="
      background: var(--primary);
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
  <Titles :type="1" :text="'Infraestrutura'" />
  <CardsBox style="max-width:1600px" :infraArray="infra" />
  </div>
</template>
<script>
import CardsBox from "../components/CardsBox.vue";
import Titles from "@/components/Titles.vue";
export default {
  components: {
    CardsBox,
    Titles
  },
  props: {
    infra:{
      required: true
    }
  },
};
</script>