<template>
  <div
    style="
      background: var(--details);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 45px;
    "
  >
    <Titles :type="2" :text="'Departamentos'" />
    <v-col cols="12" md="9" v-for="departamento in departamentos" :key="departamento.id">
      <Departaments :departamento="departamento.attributes" />
    </v-col>
  </div>
</template>
<script>
import Departaments from "../components/Departaments";
import Titles from "../../../components/Titles.vue";
export default {
  components: {
    Departaments,
    Titles,
  },
  props:{
    departamentos: {
      required: true
    }
  }
};
</script>