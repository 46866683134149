<template>
  <div
    style="
      background: var(--primary);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
    "
  >
    <ImageContainer class="mwidth" :imagens="imagens" />
  </div>
</template>
<script>
import ImageContainer from "../components/ImageContainer";
export default {
  components: {
    ImageContainer,
  },
  props:{
    imagens:{
      required: true
    }
  }
};
</script> 