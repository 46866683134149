<template>
  <div>
    <v-expansion-panels light depressed accordion>
      <v-expansion-panel>
        <v-expansion-panel-header color="var(--primary)"
          ><p class="font-text-aviso">
            {{ aviso.titulo }}
          </p>
          <template v-slot:actions>
            <font-awesome-icon
              color="var(--font-primary)"
              style="font-size: 28px"
              icon="fa-solid fa-angles-down"
            />
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <pre class="font-text-expansion-aviso">{{ aviso.descricao }}</pre>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: {
    aviso: {
      required: true,
    },
  },
};
</script>
<style>
.font-text-aviso {
  font-family: "secundary";
  color: var(--font-primary);
  font-weight: 600;
  letter-spacing: 0.04em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-style: normal;
  font-size: 25px;
  line-height: 27px;
}
.font-text-expansion-aviso {
  text-align: left;;
  font-family: "secundary";
  color: var(--primary);
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-style: normal;
  font-size: 17px;
  line-height: 21px;
}
</style>