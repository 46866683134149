import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"open-delay":"","close-delay":"","width":_vm.$screenSize >= 960 ? '80%' : '96%',"max-width":"900px"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c(VCard,{staticClass:"card-info"},[_c(VCardTitle,{staticStyle:{"justify-content":"center"}},[_c('p',{staticClass:"title-font"},[_vm._v(_vm._s(_vm.card.titulo))])]),_c(VCardText,[_c(VImg,{staticStyle:{"margin-left":"5px","margin-right":"5px","border-radius":"10px","background":"var(--tertiary)"},attrs:{"lazy-src":require("@/assets/loading.gif"),"height":"350","width":"'80%'","src":_vm.card.imagem_link}}),_c('pre',{staticClass:"description-font"},[_vm._v(_vm._s(_vm.card.descricao))])],1),_c(VCardActions,{class:_vm.$screenSize >= 960 ? '' : 'py-6'},[_c(VBtn,{attrs:{"rounded":"","color":"var(--primary)","radius":"","depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticStyle:{"color":"var(--font-primary)","padding":"18px"}},[_vm._v("Voltar")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }