<template>
  <v-col cols="12" md="11">
    <div
      style="display: flex; flex-direction: column; align-items: center"
      v-for="categorie in imagens"
      :key="categorie.id"
    >
      <Titles
        style="padding-bottom: 20px !important"
        :style="$screenSize >= 960 ? '' : 'margin-top: 20px; '"
        :type="1"
        :text="categorie.attributes.titulo"
      />
      <v-col style="padding: 0 !important" cols="12" md="11">
        <pre class="images-description-text">
          {{ categorie.attributes.descricao }}
        </pre>
      </v-col>
      <v-col cols="11" md="12">
        <VueSlickCarousel id="sem" class="images-carrousel" v-bind="settings">
          <div
            v-for="image in categorie.attributes.imagens.data"
            :key="image.id"
            class="image-item"
          >
            <div class="image-card-box">
              <v-img
                lazy-src="@/assets/loading.gif"
                :height="'120%'"
                max-height="450"
                min-height="350"
                :src="image.attributes.imagem_link"
                class="uga"
              />
              <!-- <div v-if="$screenSize >= 960" class="line"></div> -->
            </div>
          </div>
        </VueSlickCarousel>
      </v-col>
    </div>
  </v-col>
</template>
<script>
import Titles from "@/components/Titles.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    Titles,
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        lazyLoad: "ondemand",
        arrows: true,
        dots: false,
        infinite: true,
        initialSlide: 1,
        slidesToShow: this.$screenSize >= 1366 ? 3 : 2,
        slidesToScroll: 1,
        touchThreshold: 1,
        centerMode: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              fade: true,
              focusOnSelect: true,
              lazyLoad: "ondemand",
              arrows: true,
              dots: true,
              infinite: true,
              initialSlide: 1,
              speed: 200,
              slidesToShow: 1,
              slidesToScroll: 5,
              touchThreshold: 5,
              centerMode: false,
            },
          },
        ],
      },
    };
  },
  props: {
    imagens: {
      type: Array,
    },
  },
};
</script>
<style>
.images-description-text {
  font-family: "tertiary";
  color: var(--font-primary);
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-style: normal;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
}
.image-item {
  /* height: 500px; */
  height: 120%;
  max-height: 450px;
  min-height: 350px;
}
.images-carrousel {
  width: 100%;
  /* height: 500px; */
  height: 120%;
  max-height: 450px;
  min-height: 350px;
  align-items: center;
  justify-content: center;
  align-content: center;
  /* margin-bottom: 50px; */
}
.line {
  background: var(--details);
  /* height: 500px; */
  height: 100%;
  max-height: 450px;
  min-height: 350px;
  width: 8px;
  position: absolute;
  top: 0;
  border-radius: 15px;
}
#sem .slick-slide {
  margin-left: 0px;
}
.uga {
  /* margin-right: 20px;  */
  /* margin-left: calc(2vw + 8px); */
  border-radius: 15px;
  background: var(--details);
}

@media screen and (max-width: 960px) {
  .uga {
    /* margin-right: 20px;  */
    margin-left: 0px;
    border-radius: 5px;
  }
  .images-description-text {
    font-style: normal;
    font-size: 19px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;;
    word-break: break-word;
  }
}
</style>