<template>
  <div class="maps-content">
    <Titles :type="1" :text="'Onde nos encontrar'" />
    <v-col
      class="mwidth"
      style="justify-content: center; align-items: center; display: flex"
    >
      <v-row style="justify-content: space-around" class="flex">
        <v-col cols="11" md="6" lg="5">
          <p class="map-title-font">Sede social</p>
          <a
            href="https://www.google.com/maps/place/Clube+Santa+Rita/@-29.2162983,-51.3464311,15z/data=!4m5!3m4!1s0x0:0xfae6a2d5f1729ceb!8m2!3d-29.2162983!4d-51.3464311"
            target="_blank"
            class="map-title-text"
          >
            <v-img
              lazy-src="@/assets/loading.gif"
              :height="
                $screenSize > 960 && $screenSize <= 1366
                  ? '480'
                  : $screenSize >= 960
                  ? '600'
                  : '260'
              "
              style="border-radius: 3px; background: var(--tertiary)"
              src="@/assets/mapaClube.jpeg"
            ></v-img>
            <p class="mt-2">
              Rua Vêneto, 233, Nova Vicenza, Farroupilha - RS
            </p></a
          >
        </v-col>
        <div
          v-if="$screenSize >= 960"
          style="
            width: 8px;
            height: 550px;
            border-radius: 15px;
            background: var(--font-primary);
            margin-top: 122px;
            margin-bottom: 10px;
          "
        ></div>
        <v-col cols="11" md="6" lg="5">
          <p class="map-title-font">Sede campestre</p>
          <a
            href="https://www.google.com/maps/place/Sede+Campestre+Clube+Santa+Rita/@-29.2262054,-51.3819404,15z/data=!4m2!3m1!1s0x0:0xc1d9438a826cfb1?sa=X&ved=2ahUKEwi0wNmYnKr7AhWIppUCHcGjAO8Q_BJ6BAhKEAg"
            target="_blank"
            class="map-title-text"
          >
            <v-img
              lazy-src="@/assets/loading.gif"
              :height="
                $screenSize > 960 && $screenSize <= 1366
                  ? '480'
                  : $screenSize >= 960
                  ? '600'
                  : '260'
              "
              style="border-radius: 3px; background: var(--tertiary)"
              src="@/assets/mapaSede.jpeg"
            ></v-img>
            <p class="mt-2">Rua Coronel Pena de Moraes, Farroupilha - RS</p></a
          >
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import Titles from "../../../components/Titles.vue";
export default {
  components: {
    Titles,
  },
};
</script>
<style>
.maps-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100vw;
  background: var(--primary);
  height: 1000px;
  padding-bottom: 65px;
}
.map-title-font {
  font-family: "secundary";
  color: var(--font-primary);
  display: block;
  font-style: normal;
  font-size: 40px;
  line-height: 42px;
  letter-spacing: 0.05em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-top: 20px;
  text-align: center;
  word-break: keep-all;
  padding-bottom: 25px;
}
.map-title-text {
  font-family: "secundary";
  color: var(--font-primary);
  display: block;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-top: 20px;
  text-align: center;
  word-break: keep-all;
  padding-bottom: 25px;
}

@media screen and (max-width: 960px) {
  .maps-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 1100px;
    padding-bottom: 25px;
  }
  .map-title-font {
    font-family: "primary";
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.02em;
    padding-bottom: 15px;
  }
}
</style>