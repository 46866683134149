<template>
  <div class="mb-16 pb-3 mwidth">
    <Titles :type="1" :text="'Entre em contato'" />
    <div class="contacts-box">
      <div v-if="$screenSize >= 960" class="ball ball-1"></div>
      <div v-if="$screenSize >= 960" class="ball ball-2"></div>
      <div v-if="$screenSize >= 960" class="ball ball-3"></div>
      <div v-if="$screenSize >= 960" class="ball ball-4"></div>
      <!-- <p class="contacts-title-font">{{ contactsTitle }}</p> -->
      <v-row class="contacts-text-box">
        <v-col class="flex-contacts" cols="12" md="5">
          <v-col
            style="align-items: center; display: flex; flex-direction: column"
            class="contact-item"
          >
            <div class="d-flex">
              <font-awesome-icon
                color="var(--font-primary)"
                style="font-size: 35px"
                icon="fa-solid fa-phone"
                class="pt-3"
              />
              <p class="ml-4 contacts-text-font">
                <a style="text-decoration: none" href="tel:+555432611376"
                  >(54) 3261-1376</a
                >
              </p>
            </div>
            <span class="contacts-subtitle-font">(sede social)</span>
          </v-col>
          <v-row class="contact-item">
            <font-awesome-icon
              color="var(--font-primary)"
              style="font-size: 35px"
              icon="fa-brands fa-whatsapp"
              class="pt-3"
            />
            <p class="ml-4 contacts-text-font">
              <a
                style="text-decoration: none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5554996598548&text=Ol%C3%A1%2C%20tudo%20bem%3F"
                >(54) 99659-8548</a
              >
            </p>
          </v-row>
          <v-row class="contact-item">
            <font-awesome-icon
              color="var(--font-primary)"
              style="font-size: 35px"
              icon="fa-brands fa-instagram"
              class="pt-3"
            />
            <p class="ml-4 contacts-text-font">
              <a
                style="text-decoration: none"
                target="_blank"
                href="https://www.instagram.com/clube.santa.rita/"
                >clube.santa.rita</a
              >
            </p>
          </v-row>
        </v-col>
        <div
          v-if="$screenSize >= 960"
          style="
            width: 8px;
            height: 430px;
            border-radius: 15px;
            background: var(--font-primary);
            margin-top: 34px;
          "
        ></div>
        <v-col class="flex-contacts" cols="12" md="5">
          <v-col
            style="align-items: center; display: flex; flex-direction: column"
            class="contact-item"
          >
            <div class="d-flex">
              <font-awesome-icon
                color="var(--font-primary)"
                style="font-size: 35px"
                icon="fa-solid fa-phone"
                class="pt-3"
              />
              <p class="ml-4 contacts-text-font">
                <a style="text-decoration: none" href="tel:+555432686086"
                  >(54) 3268-6086</a
                >
              </p>
            </div>
            <span class="contacts-subtitle-font">(sede campestre)</span>
          </v-col>
          <v-row class="contact-item">
            <!-- <font-awesome-icon
              v-if="$screenSize < 960"
              color="var(--font-primary)"
              style="font-size: 35px"
              icon="fa-solid fa-envelope"
              class="pr-3 pt-3"
            /> -->
            <p
              :class="{
                'mr-4': $screenSize <= 1366,
              }"
              class="contacts-text-font"
              style="font-size: 23px; line-height: 25px; letter-spacing: 0em"
            >
              contato@clubesantarita.com.br
            </p>
            <!-- <font-awesome-icon
              v-if="$screenSize >= 960"
              color="var(--font-primary)"
              style="font-size: 35px"
              class="pt-3"
            /> -->
          </v-row>
          <v-row class="contact-item">
            <font-awesome-icon
              v-if="$screenSize < 960"
              color="var(--font-primary)"
              style="font-size: 35px; padding-bottom: 25px"
              icon="fa-brands fa-facebook-square"
              class="pr-3 pt-3"
            />
            <font-awesome-icon
              v-if="$screenSize >= 960"
              color="var(--font-primary)"
              style="font-size: 35px"
              icon="fa-brands fa-facebook-square"
              class="pt-3 pr-3"
            />
            <p
              class="mr-4 contacts-text-font"
              :style="$screenSize >= 960 ? '' : 'padding-bottom: 25px'"
            >
              <a
                style="text-decoration: none"
                target="_blank"
                href="https://www.facebook.com/clubesantarita"
                >Clube Santa Rita</a
              >
            </p>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Titles from "../../../components/Titles.vue";
export default {
  components: {
    Titles,
  },
  data() {
    return {
      contactsTitle: "Atendemos de Seg. a Sext. - das 8 as 17",
    };
  },
};
</script>
<style>
.contact-item {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.contacts-text-box {
  justify-content: space-around;
  display: flex;
  margin-top: 28px;
}
.flex-contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 45px;
}
.contacts-text-font {
  font-family: "secundary";
  color: var(--font-primary);
  display: block;
  font-style: normal;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: 0.04em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-top: 15px;
  text-align: center;
}
.contacts-title-font {
  font-family: "secundary";
  color: var(--font-primary);
  display: block;
  font-style: normal;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: 0.01em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-top: 34px;
  text-align: center;
  word-break: keep-all;
}
.contacts-subtitle-font {
  font-family: "secundary";
  color: var(--font-primary);
  display: block;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.06em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
  word-break: keep-all;
}
.contacts-box {
  width: 60vw;
  max-width: 1100px;
  background: var(--primary);
  height: 500px;
  margin-top: 15px;
  border-radius: 25px;
  position: relative;
}
.ball {
  width: 17px;
  background: var(--details);
  height: 17px;
  border-radius: 100%;
  position: absolute;
}
.ball-1 {
  top: 20px;
  left: 20px;
}
.ball-2 {
  top: 20px;
  right: 20px;
}
.ball-3 {
  bottom: 20px;
  left: 20px;
}
.ball-4 {
  bottom: 20px;
  right: 20px;
}
@media screen and (max-width: 1064px) {
  .contacts-box {
    height: 470px;
    width: 85vw;
  }
}
@media screen and (max-width: 960px) {
  .flex-contacts {
    padding-top: 0px;
  }
  .contacts-text-box {
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
  .contacts-box {
    height: auto;
    width: 95vw;
    border-radius: 20px;
  }
  .contacts-text-font {
    font-size: 18px;
    line-height: 20px;
    padding-top: 10px;
    text-align: center;
  }
  .contacts-title-font {
    font-family: "primary";
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0em;
    padding-top: 25px;
    text-align: center;
    word-break: keep-all;
  }
  .contact-item {
    padding-top: 27px !important;
  }
}
</style>